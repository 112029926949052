var _ = require('underscore');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="Header-settingsItem u-hideOnTablet">\n  <a href="' +
__e( mapsUrl ) +
'" class="Header-settingsLink Header-settingsLink--public">Maps</a>\n</li>\n\n<li class="Header-settingsItem u-hideOnTablet">\n  <a href="' +
__e( datasetsUrl ) +
'" class="Header-settingsLink Header-settingsLink--public">Datasets</a>\n</li>\n\n<li class="Header-settingsItem Header-settingsItem--avatar">\n  <button class="UserAvatar js-dropdown-target">\n    <img src="' +
__e( avatarUrl ) +
'" class="UserAvatar-img UserAvatar-img--medium js-user-avatar-img" />\n  </button>\n</li>\n';

}
return __p
}